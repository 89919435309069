import $ from 'jquery';

let body;
let prev = 0;

$(document).ready(function() {

    body = $('body');

    $(window).on('scroll', (e) => {
        let next = $(e.target).scrollTop();
        if (next - prev < 0) {
            body.addClass('with-scrolled-up');
        }
        else {
            body.removeClass('with-scrolled-up');
        }
        prev = next;
    });

});

import $ from 'jquery';

const pad = 5;
const clampMin = 1;
const clampMax = 12;
const classPattern = 'gallery-cols-N';
const classPatternJoined = Array.from({ length: clampMax - clampMin + 1 }, (_, i) => classPattern.replace('N', i + clampMin)).join(' ');
let galleryDom;

function callback(e) {
    galleryDom.each(function() {
        const galleryDomItem = $(this)
        const windowWidth = galleryDomItem.width();
        const thumbWidth = galleryDomItem.hasClass('gallery-smaller') ? 320 : 480;
        let n = Math.ceil((windowWidth - pad) / (thumbWidth + pad));
        if (n < clampMin) {
            n = clampMin;
        }
        else if (n > clampMax) {
            n = clampMax;
        }
        galleryDomItem.removeClass(classPatternJoined).addClass(classPattern.replace('N', n));
    });
}

$(document).ready(function() {

    galleryDom = $('.gallery-grid');

    if (galleryDom.length) {

        $(window).on('resize', callback);
        callback();

    }

});
import $ from 'jquery';

$(document).ready(function() {

    let withShowMore = $('.with-show-more, .with-show-gallery');

    if (withShowMore.length) {

        withShowMore.each(function() {
            let coll = $(this);
            let showMore = $('<p class="show-more"><a href="javasctipt:void(0)">' + (coll.hasClass('with-show-more') ? 'Pokaż więcej...' : coll.data('moreText')) + '</a></p>');
            showMore.children().on('click', function(e) {
                e.preventDefault();
                if (coll.hasClass('with-show-more')) {
                    coll.children().removeClass('hidden');
                    $(this).remove();
                    $(window).resize();
                }
                else {
                    coll.children('.image-with-meta').eq(0).find('a').click();
                }
            });
            showMore.insertAfter(coll);
        });

    }

});
import "./import-jquery";

import './floating-buttons';
import './vegas-config';
import './scroll-context';
import './scroll-up-action';
import './scroll-up-detector';
import './gallery-grid';
import './gallery-photo';
import './video-gallery';
import './captcha-validation';
import './show-more';
import './css/styles.scss';

$(document).ready(function() {
    $('body').removeClass('no-transition');
});

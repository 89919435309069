import $ from 'jquery';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

$(document).ready(function () {

    const galleryDom = $('.gallery-photo');
    const pswpElement = $('.pswp').get(0);

    if (galleryDom.length) {

        galleryDom.each(function() {

            const galleryLinks = $(this).find('.image-with-meta > a, a[rel=lightbox]');

            const items = galleryLinks.map(function() {
                const link = $(this);
                return {
                    src: link.prop('href'),
                    w: link.data('width') || link.children('img').get(0).naturalWidth,
                    h: link.data('height') || link.children('img').get(0).naturalHeight,
                    msrc: link.children().prop('src')
                };
            }).get();

            galleryLinks.on('click', function(e) {
                e.preventDefault();
                const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, {
                    index: $(this).attr('rel') === 'lightbox' ? $(this).index() : $(this).parent().index(),
                    history: false,
                    getThumbBoundsFn: function(index) {
                        const thumbnail = galleryLinks.eq(index).children();
                        const pageYScroll = $(window).scrollTop();
                        const rect = thumbnail.get(0).getBoundingClientRect(); 
                        return {
                            x: rect.left,
                            y: rect.top + pageYScroll,
                            w: rect.width
                        };
                    }
                });
                gallery.init();
            });

        });

    }

});
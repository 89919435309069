import $ from 'jquery';

const lagMs = 10;
const transitionMs = 200;
const timeoutMs = lagMs + transitionMs;

let body, targets, targetsCfg, positions, prevInContextArr, timeouts;

function callback(e) {
    if (!e || e.type !== 'scroll') {
        positions = targets.map(function() {
            return {
                top: $(this).offset().top,
                height: $(this).height()
            }
        }).get();
    }
    let top = $(window).scrollTop();
    let inContextArr = positions.map(p => p.top <= top && (p.top + p.height) > top);
    let toAdd = [];
    for (let i = 0; i < targets.length; ++i) {
        let inContext = inContextArr[i];
        let prevInContext = prevInContextArr[i];
        if (inContext !== prevInContext) {
            let classesForChange = targetsCfg[i].scrollContextTransition;
            let classesForContext = targetsCfg[i].scrollContextClass;
            if (classesForChange && timeouts[i]) {
                clearTimeout(timeouts[i]);
            }
            if (inContext) {
                toAdd.push(classesForContext);
            }
            else {
                body.removeClass(classesForContext);
            }
            if (classesForChange && prevInContext !== undefined) {
                body.addClass(classesForChange);
                timeouts[i] = setTimeout(() => {
                    body.removeClass(classesForChange);
                }, timeoutMs);
            }
        }
    }
    body.addClass(toAdd.join(' '));
    prevInContextArr = inContextArr;
}

$(document).ready(function () {

    body = $('body');
    targets = $('.scroll-context');
    targetsCfg = targets.map(function() { return $(this).data() }).get();
    positions;
    prevInContextArr = new Array(targets.length);
    timeouts = new Array(targets.length);

    $(window).on('scroll resize load', callback);
    callback();

});
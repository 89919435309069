import $ from 'jquery';

$(document).ready(function () {

    $('.menu-toggler').on('click', function(e) {
        $(this).toggleClass('icon-menu icon-cancel');
        $('body').toggleClass('with-menu-opened');
    });

    $('.scroll-down').on('click', function(e) {
        $("html, body").stop().animate({ scrollTop: $(this).closest('#site-heading').height() }, 1000);
    });

    $('.scroll-up').on('click', function(e) {
        $("html, body").stop().animate({ scrollTop: 0 }, 1000);
    });

});
import $ from 'jquery';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

$(document).ready(function() {

    const galleryItems = $('.gallery-collection').find('.video');

    if (galleryItems.length) {

        const pswpElement = $('.pswp').get(0);
        const galleryLinks = galleryItems.find('a');

        galleryLinks.on('click', function(e) {
            e.preventDefault();
            const i = $(this).parent().index();
            const video = $(this).data();
            let iframeHtml;
            if (video.platform === 'youtube') {
                iframeHtml = `
                    <iframe
                        width="960"
                        height="540"
                        src="https://www.youtube.com/embed/${video.id}"
                        frameborder="0"
                        allow="autoplay;encrypted-media"
                        allowfullscreen
                    ></iframe>
                `;
            }
            else if (video.platform === 'vimeo') {
                iframeHtml = `
                    <iframe
                        src="https://player.vimeo.com/video/${video.id}"
                        width="960"
                        height="540"
                        frameborder="0"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                    ></iframe>
                `;
            }
            else {
                throw Error('No platform named ' + video.platform);
            }
            const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, [
                {
                    html: `<div class="iframe-container">${iframeHtml}</div>`
                }
            ], {
                history: false,
                getThumbBoundsFn: function(index) {
                    const thumbnail = galleryLinks.eq(index);
                    const pageYScroll = $(window).scrollTop();
                    const rect = thumbnail.get(0).getBoundingClientRect(); 
                    return {
                        x: rect.left,
                        y: rect.top + pageYScroll,
                        w: rect.width
                    };
                }
            });
            gallery.listen('destroy', function() {
                $('.iframe-container').remove();
            });
            gallery.init();
        });

    }

});

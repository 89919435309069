import $ from 'jquery';

$(document).ready(function () {

    $('form').submit(function(event) {
        if ($(this).has("#g-recaptcha-response").length && $("#g-recaptcha-response").val() === '') {
            event.preventDefault();
            alert($("#g-recaptcha").data('errorValidating'));
        }
    });

});
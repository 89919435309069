import $ from 'jquery';

let button;
let winHeight;
let top;

function callback(e) {
    if (!e || e.type !== 'scroll') {
        winHeight = $(window).height();
    }
    const top = $(window).scrollTop();
    const shouldHide = top < 2 * winHeight;
    if (shouldHide !== button.hasClass('hidden')) {
        button.toggleClass('hidden');
    }
}

$(document).ready(function() {

    button = $('.scroll-up');

    $(window).on('resize scroll', callback);
    callback();

});
import $ from 'jquery';
import 'vegas';

$(document).ready(function() {

    let slidesWrapper = $('#site-heading.slideshow');

    const slides = slidesWrapper.find('.slide-link').map((i, slide) => ({
        src: slide.href,
        align: slide.dataset.focalPointX + '%',
        valign: slide.dataset.focalPointY + '%'
    })).get();

    slidesWrapper.vegas({
        slides,
        shuffle: true,
        delay: 4000
    });

    slidesWrapper.find('.slide-prev').on('click', () => {
        slidesWrapper.vegas('previous');
    });

    slidesWrapper.find('.slide-next').on('click', () => {
        slidesWrapper.vegas('next');
    });

    $(window).on('keydown', e => {
        if (e.keyCode == 37) { // left
            slidesWrapper.vegas('previous');
        }
        else if (e.keyCode == 39) { // right
            slidesWrapper.vegas('next');
        }
    });

});